import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import parse from "html-react-parser"
import Countdown from 'react-countdown';
import ModalTiny from "react-bootstrap/Modal"
import moment from "moment"
import "./Popup.scss"; 

const ConvertDigits = ({ number }) => {
  let temNum = number
  if (temNum < 10) {
    temNum = "0" + temNum;
  }
  var digits = temNum.toString().split('');
  var realDigits = digits.map(Number)
  return (
    <>
      {
        realDigits.map((num, i) => {
          return (
            <span className="digits">{num}</span>
          )
        })
      }
    </>
  )
};

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  return (
    <>
      <div className="days count"> <ConvertDigits number={days} />
        <p>Days</p>
      </div>
      <span className="colan">:</span>
      <div className="hours count"><ConvertDigits number={hours} />
        <p>Hours</p>
      </div>
      <span className="colan">:</span>
      <div className="minutes count"><ConvertDigits number={minutes} />
        <p>Minutes</p>
      </div>
      <span className="colan">:</span>
      <div className="seconds count"><ConvertDigits number={seconds} />
        <p>Seconds</p>
      </div>
    </>
  )

};

const PopupAlert = (props) => {
  const data = useStaticQuery(graphql`
  query {
    glstrapi {  
      sitePopup { 
        id
        Headline
        Body_Text
        Countdown
        Show_Countdown
        Show_Popup
        updatedAt
        Cookie_Refresh
      } 
    }
  }
`);


  const popupBanner = data.glstrapi?.sitePopup; 
  const [show, setShowPopup] = useState(false);

  const closePopup = () => {
    setShowPopup(false);
    localStorage.setItem("popupAlter", popupBanner?.Cookie_Refresh);
  }
  useEffect(()=>{
    let popupAlert = localStorage.getItem("popupAlter");
    let showPopup = true;
    if(popupAlert){
      var startDate = moment(popupAlert);
      var endDate = moment(popupBanner?.Cookie_Refresh);
      var duration = endDate.diff(startDate, 'minutes')
      if(duration > 0){
        showPopup = true;
      }else{
        showPopup = false;
      }
    } 
    if(popupBanner?.Show_Popup && showPopup){ 
      setShowPopup(true);
    }
  })

  return (

    <React.Fragment>
      {popupBanner &&
        <ModalTiny show={show} onHide={closePopup} className="popup-alert-modal modal-main-form modal" backdrop="static">
          <ModalTiny.Header closeButton className="contact-close-btn">
            <ModalTiny.Title className="w-100"><h2 className="text-center mb-0">{popupBanner.Headline}</h2></ModalTiny.Title>
          </ModalTiny.Header>
          <ModalTiny.Body >
            {popupBanner.Body_Text &&
              <div className="content">
                {parse(popupBanner.Body_Text)}
              </div>
            }
            {popupBanner.Show_Countdown && popupBanner.Countdown &&
              <div className="countdown">
                <Countdown
                  date={popupBanner.Countdown}
                  renderer={renderer}
                />
              </div>
            }
            <div className="countdowndate">{moment(popupBanner.Countdown).format('Do MMMM YYYY')} at {moment(popupBanner.Countdown).format('HH:mm')}
            </div>
          </ModalTiny.Body>
        </ModalTiny>
      }
    </React.Fragment >
  )
}

export default PopupAlert;